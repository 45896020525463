import React, {useEffect, useRef} from "react";
import {Avatar, Box, IconButton, Stack, TextField, Typography} from "@mui/material";
import {Mic, Send} from "@mui/icons-material";
import {useState} from "react";
import useRecorder from "../../hooks/useRecorder";
import {MainLayout} from "../../layouts/main/MainLayout";
import {grey} from "@mui/material/colors";
import {useParams} from "react-router-dom";
import axios from "../../axios/axios";
import {toast} from "react-toastify";
import useWebSocket from "../../hooks/useWebSocket";
import {AudioPlayer} from "./AudioPlayer";
import {DebugMessage} from "./DebugMessage";
import moment from "moment/moment";

export const ChatRecorder = React.memo(() => {
  const [session, setSession] = useState(null);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const {audioMessage, isApproved, isPaused, messageCounter} = useRecorder({sendMessage, session});
  const {chatId: id} = useParams();
  const [updatedValue, state] = useWebSocket(`/topic/session/`, id);
  const bottomRef = useRef();
  const formRef = useRef();

  useEffect(() => {
    if (id) {
      fetchMessages();
      axios.get(`/api/conversation/${id}`)
        .then(response => {
          setSession(response?.data);
        })
        .catch((error) => console.log(error?.message))
      setMessage("");
    } else {
      setMessages([]);
    }
  }, [id])

  const fetchMessages = () => {
    axios.get(`/api/conversation/${id}/messages`)
      .then(response => {
        setMessages(response.data);
      })
      .catch(error => {
        console.log(error);
      })
  }

  useEffect(() => {
    if (messages.length && bottomRef.current) {
      bottomRef.current.scrollIntoView({behavior: 'smooth'});
    }
  }, [messages, bottomRef.current])

  useEffect(() => {
    if (updatedValue && +id === +updatedValue?.session) {
      const newMessage = updatedValue.message;
      setMessages(prevState => {
        const items = [...prevState];
        const index = items.findIndex(i => i.id === newMessage.id);
        if (isFinite(index) && index >= 0) {
          items[index] = newMessage;
        } else {
          items.push(newMessage);
        }
        return items.sort((p1, p2) => (p1.dateCreated > p2.dateCreated) ? 1 : (p1.dateCreated < p2.dateCreated) ? -1 : 0)
      });

    }
  }, [updatedValue])

  useEffect(() => {
    if (!isPaused) {
      setMessage("");
    }
  }, [isPaused])

  async function sendMessage(sendingMessage) {
    if (sendingMessage) {
      try {
        const data = {
          session: id,
          conversation: id,
          hotwords: "",
          audio: "",
          // texts: [sendingMessage],
          message: sendingMessage,
          // json: localStorage.getItem("json_url") || null
        }
        setMessage("");
        const response = await axios.post(`/api/conversation/${session?.id}/send`, data)
        fetchMessages();
        console.log(response);
      } catch (e) {
        console.log(e);
        toast.error("Failed to send message");
      }
    }
  }

  const onSubmitHandle = (e) => {
    e.preventDefault();
    sendMessage(message)
  }

  return (
    <MainLayout
      // openLink={`/chat/${id}?json_url=${localStorage.getItem("json_url") || ""}`}
      secondary={
        <>
          <AudioPlayer
            selectedMessage={selectedMessage}
            messages={messages}
            isPaused={isPaused}
            session={session}
            setSession={setSession}
          />
        </>
      }
    >
      <Stack direction={"row"} gap={1} sx={{position: 'relative', maxHeight: "calc(100dvh - 60px)", height: "100%"}}>
        <Stack direction={"column"} sx={{
          maxHeight: "calc(100dvh - 60px)", height: "100%",
          // bgcolor: grey[50],
          flex: 1,
        }}>
          <Box
            sx={{
              zIndex: -1,
              position: "absolute",
              top: "50%", left: "50%",
              transform: "translate(-30%, -100%)",
              backdropFilter: "blur(10px)",
            }}
          >
            <img src={"/logo.svg"} alt={"Logo"} width={"60%"} height={"auto"}
                 style={{filter: "brightness(130%) blur(10px)"}}/>
          </Box>
          <Box sx={{flex: 1, p: 2, overflowY: 'auto', zIndex: 10}}>
            <Stack direction={"column"} maxWidth={"md"} sx={{mx: 'auto'}} gap={1}>
              {
                messages?.map((message, index) => {
                  const prev = messages[index - 1];
                  let isPrev = prev?.sender === message?.sender;
                  const isAgent = message?.sender === "AGENT" || message?.role === "AGENT";
                  const direction = isAgent ? "flex-start" : "flex-end";
                  const isSelected = selectedMessage?.id === message.id;
                  const bgcolor = isAgent ? `#8491F7` : `#EAEAEA`
                  return (
                    message && message?.text &&
                    <Stack key={`${message}-${index}`} direction={"column"} alignItems={direction} gap={1}
                           maxWidth={"md"}>
                      {!isPrev && <Avatar
                        sx={{background: bgcolor, color: isAgent ? "#FFF" : "dark.main", width: 35, height: 35}}/>}
                      <Stack
                        direction={"column"}                        maxWidth={"sm"}
                        onClick={() => setSelectedMessage(prev => (prev?.id === message?.id ? null : message))}
                        sx={{
                          cursor: 'pointer', p: 1.5, borderRadius: 5,
                          borderTopLeftRadius: isAgent ? 5 : "default", borderTopRightRadius: !isAgent ? 5 : "default",
                          boxShadow: isSelected ? 5 : 0,
                          color: isAgent ? "#FFF" : "dark.main",
                          background: bgcolor, minWidth: "100px",
                        }}
                      >
                        <Typography fontSize={12} color={"inherit"} gutterBottom>
                          {message?.text}
                        </Typography>
                        <Stack fullWidth direction={"row"} gap={1} sx={{color: "inherit"}}>
                          {message?.responseTime?.chatbot && <Typography fontSize={11} color={"inherit"}>Chatbot: {(message?.responseTime?.chatbot / 1000)?.toFixed(2) || ""}s</Typography>}
                          {message?.responseTime?.transcribe && <Typography fontSize={11} color={"inherit"}>Transcribe: {(message?.responseTime?.transcribe / 1000)?.toFixed(2) || ""}s</Typography>}
                          {message?.responseTime?.synthesize && <Typography fontSize={11} color={"inherit"}>Synthesize: {(message?.responseTime?.synthesize / 1000)?.toFixed(2) || ""}s</Typography>}
                          <Typography sx={{ml: "auto"}} fontSize={11} color={"inherit"}>
                            {moment(message?.dateCreated)?.fromNow()}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                  )
                })
              }
              <div ref={bottomRef}/>
            </Stack>

          </Box>
          <Box sx={{py: 1}}>
            <Stack
              ref={formRef}
              maxWidth={"md"}
              component={"form"} onSubmit={onSubmitHandle}
              direction={"row"} gap={2} alignItems={"center"}
              sx={{
                p: 1,
                pb: 0.5,
                zIndex: 10,
                mx: {xs: 1, md: "auto"},
                bgcolor: grey[50],
                borderRadius: 2,
                border: 1,
                borderColor: "divider"
              }}
            >
              <Stack direction={"column"} sx={{flex: 1}}>
                <TextField
                  autoFocus
                  value={message || audioMessage}
                  onChange={e => {
                    setMessage(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && !e.shiftKey && message) {
                      onSubmitHandle(e);
                    }
                  }}
                  multiline
                  disabled={!isPaused || !!audioMessage}
                  rows={2}
                  sx={{border: 0, "& fieldset": {border: 'none'},}}
                  InputProps={{sx: {fontSize: 12, border: 0, p: 1}}}
                  placeholder={"Write your message here..."}
                />
                <Typography fontSize={11} sx={{px: 1}}
                            color={grey[600]}>{messageCounter >= 0 && !!audioMessage && isPaused ? `Sending in ${messageCounter}` : "Hold space to start recording"}</Typography>
              </Stack>
              {
                message ?
                  <Box>
                    <IconButton
                      type={"submit"}
                      disabled={!isPaused || !isApproved}
                      sx={{width: 40, height: 40}}
                      // onClick={() => sendMessage(message)}
                    >
                      <Send/>
                    </IconButton>
                  </Box>
                  :
                  <Box>
                    <IconButton
                      type={"button"}
                      sx={{width: 40, height: 40}}
                      className={`recording-button ${isPaused || !isApproved ? "" : "active"}`}
                    >
                      <Mic/>
                    </IconButton>
                  </Box>
              }

            </Stack>
          </Box>
        </Stack>
        <DebugMessage
          selectedMessage={selectedMessage}
          messages={messages}
        />
      </Stack>
    </MainLayout>
  )
})