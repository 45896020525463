import {Box, CircularProgress, Stack, Typography} from "@mui/material";
import {grey} from "@mui/material/colors";
import React from "react";


export const LoadingScreen = () => {

  return (
    <Box sx={{position: "relative", width: `100%`, height: "100%", p: 1}}>
      <Box className={"blured"}>
        <Stack direction={"column"} gap={2}
               justifyContent={"center"} alignItems={"center"}
               maxWidth={"sm"}
               sx={{width: "100%", height: `calc(100% - 80px)`, m: 'auto', p: 3, textAlign: 'center'}}>

          <Typography variant={"h6"}>
            Just Brewing Some Chat Magic: Hang Tight!
          </Typography>
          <Typography sx={{fontSize: 12, color: grey[500], mb: 1}}>
            Hang tight! Our chat feature is gearing up to connect you with friends, colleagues, and more. Your wait will soon be over as we fine-tune the experience for seamless interactions. Thanks for your patience!
          </Typography>
          <CircularProgress size={20} />
        </Stack>
      </Box>
    </Box>
  )
}