import {MainLayout} from "../../layouts/main/MainLayout";
import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {LoadingScreen} from "../../components/LoadingScreen";

export const HomePage = () => {
  const navigate = useNavigate();
  const flowUid = localStorage.getItem("flow_uid");

  useEffect(() => {
    const timeout = setTimeout(async () => {
      if (flowUid) {
        navigate(`/flow/${flowUid}`)
      } else {
        navigate("/not-found")
      }
    }, 1500);

    return () => clearTimeout(timeout);
  }, [flowUid]);

  return (
    <MainLayout>
      <LoadingScreen />
    </MainLayout>
  )
}