import {useNavigate, useParams} from "react-router-dom";
import axios from "../../axios/axios";
import {toast} from "react-toastify";
import {MainLayout} from "../../layouts/main/MainLayout";
import {LoadingScreen} from "../../components/LoadingScreen";
import {useEffect} from "react";


export const FlowPage = () => {
  const {flowUid} = useParams();
  const navigate = useNavigate();
  const json = localStorage.getItem("json_url");

  useEffect(() => {
    if (flowUid) {
      onCreateChat()//.then(() => console.log("Create conversation")).catch(() => console.log("Failed conversation"));
    }
  }, [flowUid]);

  const onCreateChat = async () => {
    if (!flowUid) {
      return;
    }
    try {
      const phonenumber = localStorage.getItem("phonenumber") || "+38970222555";
      const response = await axios.post(`/api/conversation`, {
        json: json || null,
        flowUid: flowUid || null,
        sender: phonenumber,
        conversationType: "CHAT_BOT"
      })
      localStorage.setItem('phonenumber', phonenumber)
      navigate(`/flow/${flowUid}/chat/${response?.data?.id}`);
    } catch (e) {
      toast.error(e.message);
      navigate("/not-found")
      console.log(e)
    }
  }

  return (
    <MainLayout>
      <LoadingScreen />
    </MainLayout>
  )
}