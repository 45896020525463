import {Typography} from "@mui/material";

export const NotFound = () => {
  return (
    <div id="notfound">
      <div className="notfound">
        <div className="notfound-404">
          <Typography>Oops! Page not found</Typography>
          <Typography fontSize={"9rem"} fontWieght={700} color={"primary"}>404</Typography>
        </div>
        <Typography>we are sorry, but the page you requested was not found</Typography>
      </div>
    </div>
  )
}