import React, {useEffect, useRef} from "react";
import {Avatar, Box, IconButton, Stack, Typography} from "@mui/material";
import {useState} from "react";
import {useParams} from "react-router-dom";
import axios from "../../axios/axios";
import useWebSocket from "../../hooks/useWebSocket";
import useAudioQueue from "./useAudioQueue";
import {Pause, PlayArrow, SkipNextOutlined, SkipPreviousOutlined} from "@mui/icons-material";
import {grey, orange} from "@mui/material/colors";
import moment from "moment";

export const ChatPreview = React.memo(() => {
  const [session, setSession] = useState(null);
  const [messages, setMessages] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const {chatId: id} = useParams();
  const { play, pause, selectMessageById, handleNext, handlePrev, isPlaying, currentIndex, hasPrev, hasNext, canPlay, playingMessage } = useAudioQueue(messages);
  const [updatedValue, state] = useWebSocket(`/topic/session/`, id);
  const bottomRef = useRef();

  useEffect(() => {
    if (id) {
      fetchMessages();
      axios.get(`/api/conversation/${id}`)
        .then(response => {
          setSession(response?.data);
        })
        .catch((error) => console.log(error?.message))
    } else {
      setMessages([]);
    }
  }, [id])

  const fetchMessages = () => {
    axios.get(`/api/conversation/${id}/messages`)
      .then(response => {
        setMessages(response.data);
      })
      .catch(error => {
        console.log(error);
      })
  }

  useEffect(() => {
    if (messages.length && bottomRef.current) {
      bottomRef.current.scrollIntoView({behavior: 'smooth'});
    }
  }, [messages?.length, bottomRef.current])

  useEffect(() => {
    if (updatedValue && +id === +updatedValue?.session) {
      const newMessage = updatedValue.message;
      setMessages(prevState => {
        const items = [...prevState];
        const index = items.findIndex(i => i.id === newMessage.id);
        if (isFinite(index) && index >= 0) {
          items[index] = newMessage;
        } else {
          items.push(newMessage);
        }
        return items.sort((p1, p2) => (p1.dateCreated > p2.dateCreated) ? 1 : (p1.dateCreated < p2.dateCreated) ? -1 : 0)
      });

    }
  }, [updatedValue])

  return (
    <>
      <Stack direction={"row"} gap={1} sx={{position: 'relative', maxHeight: "calc(100dvh - 60px)", height: "100%"}}>
        <Stack direction={"column"} sx={{
          maxHeight: "calc(100dvh - 1px)", height: "100dvh",
          flex: 1,
        }}>
          <Box
            sx={{
              zIndex: -1,
              position: "absolute",
              top: "50%", left: "50%",
              transform: "translate(-30%, -100%)",
              backdropFilter: "blur(10px)",
            }}
          >
            <img src={"/logo.svg"} alt={"Logo"} width={"60%"} height={"auto"}
                 style={{filter: "brightness(130%) blur(10px)"}}/>
          </Box>
          <Box sx={{flex: 1, p: 2, overflowY: 'auto', zIndex: 10}}>
            <Stack direction={"column"} maxWidth={"md"} sx={{mx: 'auto'}} gap={1}>
              {
                messages?.map((message, index) => {
                  const prev = messages[index - 1];
                  let isPrev = prev?.sender === message?.sender;
                  const isAgent = message?.sender === "AGENT" || message?.role === "AGENT";
                  const direction = isAgent ? "flex-start" : "flex-end";
                  const isSelected = selectedMessage?.id === message.id;
                  const isPlayingCurrentMessage = playingMessage?.id === message?.id && isPlaying;
                  const bgcolor = isAgent ? isPlayingCurrentMessage ? "#6978f1" : `#8491F7` : isPlayingCurrentMessage ? "#d5d4d4" : `#EAEAEA`
                  return (
                    message && message?.text &&
                    <Stack key={`${message}-${index}`} id={`message-${message?.id}`} direction={"column"} alignItems={direction} gap={1}
                           maxWidth={"md"}>
                      {!isPrev && <Avatar
                        sx={{background: bgcolor, color: isAgent ? "#FFF" : "dark.main", width: 35, height: 35}}/>}
                      <Stack
                        direction={"column"}
                        maxWidth={"sm"}
                        onClick={() => {
                          setSelectedMessage(prev => (prev?.id === message?.id ? null : message));
                          if (message?.id) {
                            selectMessageById(message?.id);
                          }
                        }}
                        sx={{
                          cursor: 'pointer', p: 1.5, borderRadius: 5,
                          borderTopLeftRadius: isAgent ? 5 : "default", borderTopRightRadius: !isAgent ? 5 : "default",
                          boxShadow: isSelected || isPlayingCurrentMessage ? 5 : 0,
                          color: isAgent ? "#FFF" : "dark.main",
                          background: bgcolor, minWidth: "100px",
                        }}
                      >
                        <Typography fontSize={12} color={"inherit"} gutterBottom>
                          {message?.text}
                        </Typography>
                        <Stack fullWidth direction={"row"} gap={1}>
                          {message?.responseTime?.chatbot && <Typography fontSize={11} color={"inherit"}>Chatbot: {(message?.responseTime?.chatbot / 1000)?.toFixed(2) || ""}s</Typography>}
                          {message?.responseTime?.transcribe && <Typography fontSize={11} color={"inherit"}>Transcribe: {(message?.responseTime?.transcribe / 1000)?.toFixed(2) || ""}s</Typography>}
                          {message?.responseTime?.synthesize && <Typography fontSize={11} color={"inherit"}>Synthesize: {(message?.responseTime?.synthesize / 1000)?.toFixed(2) || ""}s</Typography>}
                          <Typography sx={{ml: "auto"}} fontSize={11} color={"inherit"}>
                            {moment(message?.dateCreated)?.fromNow()}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                  )
                })
              }
              <div ref={bottomRef}/>
            </Stack>
          </Box>

          <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} gap={2} sx={{py: 4}}>
            <IconButton
              disabled={!hasPrev}
              onClick={handlePrev}
            >
              <SkipPreviousOutlined/>
            </IconButton>
            <IconButton
              disabled={!canPlay}
              onClick={() => isPlaying ? pause() : play()}
            >
              {isPlaying ? <Pause/> : <PlayArrow />}
            </IconButton>
            <IconButton
              disabled={!hasNext}
              onClick={handleNext}
            >
              <SkipNextOutlined/>
            </IconButton>
          </Stack>
        </Stack>
      </Stack>
    </>
  )
})