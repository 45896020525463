import './App.css';
import {CircularProgress, createTheme, CssBaseline, ThemeProvider} from "@mui/material";
import {Navigate, Route, Routes, useNavigate, useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import axios, {AUTH_TOKEN} from "./axios/axios";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {ChatRecorder} from "./pages/chat-recorder/ChatRecorder";
import {HomePage} from "./pages/home-page/HomePage";
import {NotFound} from "./pages/not-found/NotFound";
import {MainLayout} from "./layouts/main/MainLayout";
import {FlowPage} from "./pages/flow/flow-page";
import {ChatPreview} from "./pages/chat-preview/ChatPreview";

const darkTheme = createTheme({
  palette: {
    mode: 'light',
    background: {
      // default: "#212121"
    },
    light: {
      main: "#FFF"
    },
    primary: {
      main: "rgba(132, 145, 247, 1)"
    },
    dark: {
      main: "#2C2B2A"
    },
    secondary: {
      main: "#D7E8F4"
    }
  },
});

function App() {
  let [searchParams] = useSearchParams();
  const flowUid = searchParams.get("flowUid");
  const json = searchParams.get("json_url")

  useEffect(() => {
    console.log(flowUid);
    if (flowUid) {
      localStorage.setItem("flow_uid", flowUid);
      localStorage.removeItem("json_url");
      // navigate("/");
    }
    if (json) {
      // Decode the base64-encoded URL
      const decodedUrl = atob(json);

      // Save the decoded URL to localStorage
      localStorage.setItem("json_url", decodedUrl);
    } else {
      console.log("json_url not found in searchParams");
    }
  }, [flowUid, json]);

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline/>
      <ToastContainer
        position={"top-right"}
        autoClose={2000}
        hideProgressBar
        newestOnTop
      />

      <Routes>
        {/*<Route path={"/chat/:chatId"} element={<ChatRecorder asriId={null}/>}/>*/}
        <Route path={"/chat/:chatId/preview"} index element={<ChatPreview asriId={null}/>}/>
        <Route path={"/flow/:flowUid/chat/:chatId"} index element={<ChatRecorder asriId={null}/>}/>
        <Route path={"/flow/:flowUid"} index element={<FlowPage asriId={null}/>}/>
        <Route path={"/not-found"} element={<MainLayout><NotFound/></MainLayout>}/>
        <Route path={"/"} index element={<HomePage/>}/>
        <Route path={"*"} element={<Navigate to={"/not-found"}/>}/>
      </Routes>

    </ThemeProvider>
  );
}

export default App;
