import { useState, useRef, useEffect } from "react";

const useAudioQueue = (messages) => {
  const [audioQueue, setAudioQueue] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(new Audio());

  useEffect(() => {
    // Extract valid audio messages with ID mapping
    const validAudioMessages = messages
      .filter((msg) => msg.audio && msg.audio.trim() !== "");
    console.log("messages", messages);
    console.log("Audios", validAudioMessages);
    setAudioQueue(validAudioMessages);
  }, [messages]);

  useEffect(() => {
    if (isPlaying && audioQueue.length > 0 && currentIndex < audioQueue.length) {
      playAudio(audioQueue[currentIndex].audio);
    }
  }, [currentIndex, isPlaying, audioQueue]);

  const playAudio = (audioSrc) => {
    if (!audioSrc) return;
    // Ensure the gateway URL does not have a trailing '/'
    const baseUrl = process.env.REACT_APP_GATEWAY;
    const apiPath = "/api/file/audio"; // Ensure this remains fixed

    // Construct URL and append `audioSrc` as a query parameter manually
    const url = new URL(apiPath, baseUrl);
    url.searchParams.append("path", audioSrc);

    console.log(url.href)
    // Construct valid audio URL
    audioRef.current.src = url.href;
    audioRef.current?.play()
      .then(() => setIsPlaying(true))
      .catch((err) => console.warn("Playback prevented:", err));

    audioRef.current.onended = () => {
      handleNext(); // Auto-play next audio
    };
    const msgBox = document.getElementById(`message-${audioQueue[currentIndex]?.id}`);
    if (msgBox) {
      msgBox?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const play = () => {
    if (!isPlaying && canPlay) {
      playAudio(audioQueue[currentIndex]?.audio);
    }
  };

  const pause = () => {
    audioRef.current.pause();
    setIsPlaying(false);
  };

  const selectMessageById = (id) => {
    const index = audioQueue.findIndex((msg) => msg.id === id);
    if (index !== -1) {
      setCurrentIndex(index);
      playAudio(audioQueue[index].audio);
    }
  };

  const handleNext = () => {
    if (hasNext) {
      setCurrentIndex((prev) => prev + 1);
    } else {
      setIsPlaying(false); // Stop if at the last audio
    }
  };

  const handlePrev = () => {
    if (hasPrev) {
      setCurrentIndex((prev) => prev - 1);
      playAudio(audioQueue[currentIndex - 1].audio);
    }
  };

  // Boolean values
  const hasPrev = !!audioQueue[currentIndex - 1]?.audio && currentIndex > 0;
  const hasNext = !!audioQueue[currentIndex + 1]?.audio;
  const canPlay = audioQueue.length > 0;

  console.log(audioQueue, currentIndex, !!audioQueue[currentIndex - 1]?.audio && currentIndex > 0);

  return { play, pause, selectMessageById, handleNext, handlePrev, isPlaying, currentIndex, hasPrev, hasNext, canPlay, playingMessage: audioQueue[currentIndex] };
};

export default useAudioQueue;
